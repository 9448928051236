.modal {
  position: fixed;
  z-index: 1000; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
  transition: all 0.3s;
}


.modal-wrapper {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: #fefefe;
  border: 1px solid #888;
  /* width: 80%;  */
}
.modal-wrapper-content {
  position: relative;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  height: 100%;
}
.modal-content {
  position: relative;
  margin: 0px;
  /* padding: .5em 1rem; */
  margin: .5em 1rem;
  max-width: 100%;
  /* min-height: 100%; */
  max-height: 100%;
  overflow-y: auto;
}

.modal-footer {
  padding: 1rem;
  padding-top: 0px;
}
.modal-footer-buttons {
  padding: 1rem;
  padding-top: 0px;
  display: flex;
  justify-content: end;
  gap: 0.5rem;
}

.modal-example-children {
  position: absolute;
  left: 0px;

  width: 100%;
  height: 100%;
  top: 0px;
  margin: 0px;
  padding: 0px;
  overflow: auto;
}
