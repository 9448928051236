/**
Если нужно переназначить стили,
то локально

Например:

<div className="agk-table general-table-wrapper"> ... </div>

.agk-table.general-table-wrapper {
  height: 100%;
  width: 100%;
}
*/

.input-table-wrapper {
  overflow: auto;
  background-color: var(--table-body-color);
}

.general-table-wrapper {
  overflow: auto;
  background-color: var(--table-body-color);
  border: 1px solid var(--table-border-color);
  &.no-border {
    border: none;
  }
}
.border {
  border: 1px solid var(--table-border-color);
}

/* общие стили */
.general-table,
.input-table {
  position: relative;
  border-collapse: collapse;
  background-color: var(--table-body-color);

  thead {
    position: sticky;
    top: 0;
    z-index: 1;

    th {
      text-align: start;
      vertical-align: top;
    }
  }

  th {
    font-size: smaller;

    &.fixed-column {
      position: sticky;
      left: 0px;
      background-color: var(--table-header-color);
      z-index: 0;
    }
  }

  td {
    background-color: var(--table-body-color);
    &.fixed-column {
      left: 0px;
      background-color: var(--table-body-color);
      z-index: 0;
    }
  }

  // это позволяет скорректировать ширину колонки по ее содержимому (с min|max не работает)
  .checkbox-column,
  .button-column {
    width: 0px;
  }

  &.hover-row {
    tr:hover td {
      background: var(--secondary-color-10);
    }
  }
  &.hover-cell {
    td:hover {
      background-color: var(--secondary-color-10);
    }
  }
  &.hover-row-cell {
    tr:hover td {
      background: var(--secondary-color-10);
    }
    td:hover {
      background-color: var(--secondary-color-20) !important;
    }
  }
}

/* отдельные стили */
.input-table {
  thead {
    background-color: transparent;
    height: auto;
  }
  th {
    text-align: start;
    vertical-align: bottom;
    background-color: white;
  }

  td,
  th {
    padding: 0.25rem 0.25rem;
    box-shadow: none;

    &.column-number {
      padding-left: 0.5rem;
    }
  }
}

/* отдельные стили */
.general-table {
  thead {
    height: var(--height);

    th {
      text-align: start;
      vertical-align: top;
      background-color: var(--table-header-color);
    }
  }

  td,
  th {
    padding: 0.375rem 0.75rem;
    box-shadow: 0px 0px 0px 0.5px var(--table-border-color) inset;
  }

  &.use-vertical-borders {
    thead:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: var(--table-border-color);
    }

    td,
    th {
      box-shadow: none;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid var(--table-border-color);
    }
  }
}
