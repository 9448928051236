a {
  text-decoration: none;
}

a.active,
.button-tab.active {
  color: var(--active-link-color) !important;
  background-color: var(--active-link-color-opacity-10) !important;
}

.button-tab {
  transition: 0.4s;
}

input,
button,
textarea,
select {
  font-family: inherit;
}

select {
  border-radius: 4px;
}

input:invalid,
input[aria-invalid="true"],
select:invalid,
select[aria-invalid="true"] {
  border: 1px solid var(--danger-color);
}

/* input:focus:invalid, input[aria-invalid="true"]:focus,
select:focus:invalid, select[aria-invalid="true"]:focus {
  outline-color: var(--danger-color) !important;
} */

.error-message {
  color: var(--danger-color);
}

/* убираем стрелки вверх/вниз у инпута типа number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.horizontal-tabs {
  display: flex;
  gap: 0.25rem;
  align-items: center;

  &.scroll-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 1px;
  }
}
