.card-link {
  position: relative;
  border-radius: 5px;
  box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 30%), 0px 1px 1px 0px rgb(0 0 0 / 24%), 1px 1px 3px 0px rgb(0 0 0 / 22%);
  transition: 0.35s;
  font-size: 1.15rem;

  a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
  }

  .card-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    border-radius: 8px;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }
  }
  .card-title {
    padding: 0.5rem;
  }

  &:hover {
    transform: scale(1.035);
    box-shadow: 0px -1px 10px -1px rgb(2 132 199 / 30%), 0px 1px 1px 0px rgb(2 132 199 / 24%),
      1px 1px 3px 0px rgb(2 132 199 / 22%);
  }
}
