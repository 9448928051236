/* в ui-components попробовать занести все цвета из tailwind.config в константы и там вызывать те константы */

/*
 ОБЩАЯ СТИЛИСТИКА:

  КЛЮЧЕВОЕ ПРАВИЛО и главная просьба: 
    ЕСЛИ хотите что-то новенькое в общей стилистике
     >>> !!! советуйтесь с Леной !!! <<<
    EСЛИ в описании что-то непонятно или есть ошибка
     >>> !!! спрашивайте Лену !!! <<<

ЦВЕТА:
 Все цвета брать из констант ИЛИ tailwind theme.
 Группы цветов используемые из tailwind: https://tailwindcss.com/docs/customizing-colors
  >>> sky, slate <<<

ОТСТУПЫ:
  1) в межобъектных отсупах может присутствовать числа:
    >>> 1, 2, 3, 4 <<<
    2 - Если не знаете что ставить - это 0.5rem
    Например: gap-2, space-x-2, m-2 и т.п
    Все зависит от ситуации, нужно смотреть по "красивости".

  2) content-страничка ВСЕГДА имеет внутрений отступ 2 
    >>> p-2 <<<

ТАБЛИЦЫ:
  1) Таблица квадратная. Без border-radius. Без вопросов.
  2) Таблицы имеют границу, если имеют с выделеный цветом <header> 
    >>> border: 1px solid var(--table-border-color); <<<
    цвета для таблицы ниже в константах

ПРОЧЕЕ:
  1) Основная минимальная ВЫСОТА высота объектов типа:
    кнопка, селект, хидер таблицы и т.п.
    >>> 38px <<<
  2) border-radius: 4px или 5px - в основном.
*/

* {
   /* tailwind class: h-primary */
  --height: 38px;

  /* tailwind-color: slate-50 или tailwind class: bg-primary-bg */
  --primary-bg-color: #f8fafc;
  /* tailwind-color: sky-600 */
  --secondary-color: #0284c7;
  /* tailwind-color: sky-50 и sky-100 соответсвенно */
  --secondary-color-10: #f0f9ff;
  --secondary-color-20: #e0f2fe;

  --disabled-bg-color: #f8fafc;

  /* цвета текса как в theme из ui-components */
 
  /* tailwind class: text-text или text-primary-text */
  --primary-text-color: #474D66;
  /* tailwind class: text-text-light */
  --light-text-color: #8f95b2; 
  /* tailwind class: text-text-disabled */
  --disabled-text-color: #c1c4d6;
  
  /* ____ более частные случаи ____ */
  
  --active-link-color: var(--secondary-color); 
  /* tailwind-color: sky-50 */
  --active-link-color-opacity-10: rgba(2, 132, 197, 10%); 
  
  /* tailwind-color: slate-100 */
  --table-header-color: #f3f4f6;
  --table-body-color: white;
  /* tailwind-color: slate-200 */
  --table-border-color: #e2e8f0;

  /* theme tailwind-color: info */
  --info-color: #0284c7;
  /* theme tailwind-color: success */
  --success-color: #14b8a6;
  /* theme tailwind-color: danger */
  --danger-color: #EF4444;
  /* theme tailwind-color: info */
  --warning-color:#faad14;
  /* theme tailwind-color: default */
  --default-color:#64748b;
}

/* @layer base {
  :root {
    --primary-bg-color: #f8fafc;
  }
} */