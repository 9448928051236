
@media print  {
  .printable, .printable div, .printable span, .printable canvas, .printable p {
    visibility: visible;
  }
  .printable .print-exclude {
    visibility: hidden;
  }
  .printable.print-holst {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    visibility: visible;
  }
  *:not(.printable) {
    visibility: hidden;
  }
}