.block-spinner-wrapper {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
   
    background-color: rgba(255,255,255,0.6);
    /*transition: all 0.3s;*/
}