.data-error-message-danger::before,
.data-error-message-warning::before {
  content: "";
  display: inline-block;
  margin-right: 0.25rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.data-error-message-danger::before {
  background-color: var(--danger-color);
}
.data-error-message-warning::before {
  background-color: var(--warning-color);
}
