/* .wrapper{
  max-width: 560;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 500;
  pointer-events: none;
  } */
  
  #toasters .animation {
  display: flex;
  /* height: 0; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  transition: all 240ms cubic-bezier(0, 0, 0.2, 1) 0s;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover -50%;
  min-width: 200px;
  width: fit-content;
  }
  
  #toasters.block {
  position : absolute;
  display : grid;
  row-gap : .5em;
  justify-items: center;
  grid-template-rows : max-content;
  top : 50%;
  z-index : 100000;
  left : 50%;
  transform: translate(-50%, -50%);
  transition: all 240ms cubic-bezier(0, 0, 0.2, 1) 0s;
  }
  
  /* .toaster-anim-open{
    transition: .5s all;
    animation: toaster-popup .5s both;
  } */
  
  #toasters .anim-open {
    animation: 240ms cubic-bezier(0.175, 0.885, 0.32, 1.175) 0s 1 normal both running toaster-popup;
  }

  .close-toaster {
  color: #aaa;
  float: right;
  font-size: 20px;
  font-weight: bold;
  }

  .body-toaster {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr max-content;
  align-items: baseline;
  /* column-gap: space-between */
  }

  .toaster-span {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: .5em;
    /* margin: 5; */
    justify-content: end;
  }
  
  @keyframes toaster-popup {
    0% {
      opacity: 0;
      transform: translateX(120%);
    }
  100% {
      transform: translateY(0px);
    }
  }
  