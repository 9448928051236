::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
@media screen and (max-width: 610px) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(100, 116, 139, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(51, 65, 85, 0.5);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(15, 23, 42, 0.5);
}