.tooltip {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: 2px 2px 7px -1px rgba(0, 0, 0, .25);
  background-color: white;
}

.tooltip.top {
  bottom: 120%;
  transform: translateX(-50%);
  left: 50%;
}

.tooltip.bottom {
  top: 120%;
  transform: translateX(-50%);
  left: 50%;
}

.tooltip.right {
  left: 110%;
  transform: translateY(-50%);
  top: 50%;
}

.tooltip.left {
  right: 110%;
  transform: translateY(-50%);
  top: 50%;
}