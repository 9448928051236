/* Чтобы было поверх всего, кроме хидера */
.react-select__menu {
  z-index: 10 !important;
}
.react-select__single-value {
  color: var(--primary-text-color) !important;
}
.react-select__single-value--is-disabled { 
  opacity: 0.4;
}
